
export const API_BASE_URL = 'https://www.smartoffice.pk/wsapi/v1';
export const API_IMAGES_URL = 'https://www.smartoffice.pk/v1/images/';
export const FILES_URL = 'https://www.smartoffice.pk/v1/files/';
export const TOKEN = "token";
export const IDENTITY = "userIdentity";
export const IMAGE_TYPES = ['png', 'jpg', 'jpeg'];
export const FILE_TYPES = ['doc', 'txt', 'pdf', 'jpg'];
export const DATETIME_FORMAT = "D MMM YYYY";
export const DATE_FORMAT = "D MMM YYYY";
export const IMAGES_URL = process.env.PUBLIC_URL + '/assets/images/';
export const LANG = 'fr';
export const BASIC_AUTH_TOKEN = 'RVpKTlIwYThwRFpEMGxOT281MHg6MXh5V1JnQXFqVjN5aVZ5NVhxYUk=';